<template>
  <NewExpenseUser
    :EX="EX"
    :warehouses="warehouses"
    :countries="countries"
    :expenseTypes="expenseTypes"
    :banks="banks"
    :payees="payees"
    @save="save"
  />
</template>

<script>
  import NewExpenseUser from "./ExpenseCreationSection/ExpenseCreationSection";
  import {Expenses} from "../../models/Expenses";
  import {expenseMixin} from "../../../../../mixins/expenseMixins/expenseMixin";
  import {EXPENSES_SYSTEM_TYPES} from "../../../../../staticData/staticVariables";

  export default {
    name: "ExpenseCreation",
    components: {
      NewExpenseUser,
    },

    mixins: [expenseMixin],

    created() {
      this.$store.dispatch('getExpensesCreate').then(response => {

        let respData = this.getRespData(response)
        this.warehouses = respData.warehouses
        this.countries = respData.countries
        this.expenseTypes = respData.expense_type_instances[EXPENSES_SYSTEM_TYPES.DEFAULT_TYPE.value]
        this.banks = respData.expense_type_instances[EXPENSES_SYSTEM_TYPES.BANK_TYPE.value]
        this.payees = respData.expense_type_instances[EXPENSES_SYSTEM_TYPES.PAYEE_TYPE.value]

        console.log(123, response, this.expenseTypes, this.banks, this.payees);
      }).catch(error => this.createErrorLog(error))
    },

    data() {
      return {
        EX: new Expenses(),
        warehouses: [],
        countries: [],
        expenseTypes: [],
        banks: [],
        payees: [],
      }
    },

    methods: {

    }

  }
</script>

<style scoped>

</style>
